import {Preference} from "./Preference";

export const makePrefCopy = (oldPref: Preference): Preference => {
  let pref = {...oldPref};
  pref.properties = Object.assign({}, oldPref.properties);

  return pref;
}

export const toInsertVector = (pref: Preference) => {
  return {
    type_key: pref.associatedType.id,
    polarity: pref.groupKey,
    negotiability: pref.negotiability,
    carer_global_id: pref.carerId,
    settings: pref.properties,
  }
}