import {ToastNotification} from "carbon-components-react";

const SaveFailureNotify = () => {
  return (
      <ToastNotification
          className={"app-notify"}
          title={"Wystąpił błąd przy zapisywaniu."}
          kind={"error"}
          subtitle={"Wystąpił problem z aplikacją. Skontaktuj się z działem IT LAB"}
          timeout={7000}
      />
  )
}

export default SaveFailureNotify