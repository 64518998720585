import {Preference, TypeBehavior} from "../Preference";
import PreferenceValueInput from "../../components/MaxInput/PreferenceValueInput";

export type MaxAgeProperties = {
  value: number | ""
}

type MaxWeight = Preference & {
  properties: MaxAgeProperties
}

export const MaxAgeBehavior: TypeBehavior<MaxWeight> = {
  formCreator: options => (<><PreferenceValueInput {...options} placeholder={'Max'} label={'Max'}/></>),
  marshaller: null,
  jsonReplacer: null,
  initializeProperties: pref => {
    pref.properties.value = "";
  }
}

export default MaxAgeBehavior;