import {Modal, ModalProps} from "carbon-components-react";
import {PropsWithChildren} from "react";

type LocalProps = ModalProps & {
  open: boolean,
  primaryButtonDisabled: boolean
}

const DeleteModal = (props: PropsWithChildren<LocalProps>) => {
  return (
      <Modal primaryButtonText={"Usuń"}
             secondaryButtonText={"Anuluj"}
             danger={true}
             {...props}>
        {props.children}
      </Modal>
  )

}

export default DeleteModal;