import {useMsal} from "@azure/msal-react";
import {InteractionRequiredAuthError, SilentRequest} from "@azure/msal-browser";

export default function useMsalToken() {

  const {instance} = useMsal();

  const accessTokenRequest: SilentRequest = {scopes: ['User.Read'], account: instance.getAllAccounts()[0],};

  return {
    ensureToken: async (): Promise<string> => {
      return new Promise<string>((resolve, reject) => {
        instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
          return resolve(accessTokenResponse.accessToken);
        }).catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
          }

          reject('Could not fetch msal access token ' + error);
        });
      });
    }
  }
}
