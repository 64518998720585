import useApi from "./useApi";
import {useQuery} from "react-query";
import {PreferencePreset, PreferenceType} from "../types/PreferenceTypes";

export type Selectable = PreferenceType | PreferencePreset;

export type OptionsData = {
  types: PreferenceType[]
  presets: PreferencePreset[]
  selectables: Selectable[],
  settingsLabels: Record<number, Record<string, Record<string, string>>>,
  canMigrateProfiles: boolean
}

export default function useOptions() {
  const api = useApi();

  return useQuery<OptionsData, Error>(['frontendOptions'], api.getOptions, {
    refetchOnWindowFocus: false
  });
}