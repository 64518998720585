import {Preference, TypeBehavior} from "../Preference";
import PreferenceValueInput from "../../components/MaxInput/PreferenceValueInput";
import {PreferenceFormProps} from "../PreferenceFormProps";
import {useTranslation} from "react-i18next";

export type NightShiftsInWeekProperties = {
  value: number | ""
}

type NightShiftsInWeek = Preference & {
  properties: NightShiftsInWeekProperties;
}

export const NightShiftsInWeekBehavior: TypeBehavior<NightShiftsInWeek> = {
  formCreator: options => <NightShiftsForm {...options} />,
  marshaller: null,
  jsonReplacer: null,
  initializeProperties: pref => {
    pref.properties.value = "";
  }
}

export default NightShiftsInWeek;

const NightShiftsForm = (options: PreferenceFormProps<NightShiftsInWeek>) => {
  const {t} = useTranslation();

  return (
      <PreferenceValueInput {...options} placeholder={t('value-required')} label={t('night-shifts-max-week')}/>
  )
}