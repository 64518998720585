import {Preference} from "../../types/Preference";
import {Dropdown, Toggle} from "carbon-components-react";
import {getBehaviorByPref, PreferenceGroup} from "../../types/PreferenceTypes";
import {ValidationContext} from "../../hooks/useValidation";
import {useTranslation} from "react-i18next";

type LocalProps = {
  editedPref: Preference
  onUpdate: (newState: Preference) => any
  validation: ValidationContext
  settingsLabels: Record<string, Record<string, string>>
}

const PreferenceForm = (props: LocalProps) => {

  const {t} = useTranslation();

  const labels = {
    [PreferenceGroup.REQUIREMENTS]: t('polarity-preference'),
    [PreferenceGroup.EXCLUSIONS]: t('polarity-exclusion')
  }

  const preferenceGroupOptions: PreferenceGroup[] = [
    PreferenceGroup.REQUIREMENTS,
    PreferenceGroup.EXCLUSIONS
  ];

  const onGroupSelect = (data: any) => {
    props.editedPref.groupKey = data.selectedItem;
    props.onUpdate(props.editedPref);
    props.validation.clear('polarity');
  }

  const onNegotiabilityChange = (checked: boolean) => {
    props.editedPref.negotiability = checked;
    props.onUpdate(props.editedPref);
  }

  const prefBehavior = getBehaviorByPref(props.editedPref);

  return (
      <div className={`pref-${props.editedPref.associatedType.id}-form`}>
        <div className="bx--row mb-5 basic-preference-settings">
          <div className={"bx--col-lg-8"}>
            <Dropdown<PreferenceGroup>
                id={'select-group'}
                label={''}
                items={preferenceGroupOptions}
                itemToString={(i) => labels[i]}
                onChange={onGroupSelect}
                selectedItem={props.editedPref.groupKey}
                titleText={t('pref-polarity')}
                invalidText={props.validation.getError('polarity')}
                invalid={props.validation.hasError('polarity')}
            />
          </div>
          <div className="bx--col-lg-8">
            <Toggle id={'negotiability-select'} labelText={t('negotiability')} labelA={t('no')} labelB={t('yes')}
                    onToggle={onNegotiabilityChange} toggled={props.editedPref.negotiability}/>
          </div>
        </div>

        {prefBehavior.formCreator !== null && prefBehavior.formCreator({
          preference: props.editedPref,
          recordUpdated: (newState) => props.onUpdate(newState),
          validation: props.validation,
          settingsLabels: props.settingsLabels
        })}
      </div>
  )
}

export default PreferenceForm;