import useSyncState, {SyncStateData} from "../../hooks/useSyncState";
import {Button, Loading, NotificationKind, ToastNotification, ToolbarTitle} from "carbon-components-react";
import React, {ReactElement, useEffect, useState} from "react";
import {useMutation, useQueryClient} from "react-query";
import useApi from "../../hooks/useApi";
import SystemSyncInfo from "./SystemSyncInfo";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";


type LocalProps = {
  carerId: string,
  showMigrationButton: boolean,
  notify: (notification: ReactElement | null) => any
}

const MigrationCheckToast = (props: { success: boolean }) => (
    <ToastNotification title={props.success ? 'Pomyślnie oznaczono profil jako zmigrowany' : 'Nie udało się oznaczyć profilu jako zmigrowany'}
                       kind={props.success ? "success" : "error"} key={Math.random().toString(2)} className={"app-notify"}/>
)

const SyncInfo = (props: LocalProps) => {

  const location = useLocation();

  const navigate = useNavigate();

  const api = useApi();

  const [isMigrated, setMigrated] = useState<boolean>(false);

  const {t} = useTranslation();

  const queryClient = useQueryClient();

  const syncQuery = useSyncState(props.carerId);

  useEffect(() => {
    if (syncQuery.data !== undefined) {
      setMigrated(syncQuery.data.migrated);
    }
  }, [syncQuery.data])

  const title = (
      <div className={"bx--flex-line"}>
        <span className="mr-5">{t('enova-sync')}</span>
        <Loading withOverlay={false} active={syncQuery.isLoading} small={true}/>
      </div>
  );

  const markMigrated = useMutation(() => api.markMigrated(props.carerId), {
    onSuccess: data => {
      setMigrated(true);
      navigate(location.pathname.replace('false', 'true') + location.search)
      queryClient.invalidateQueries('prefs');
      props.notify(<MigrationCheckToast success={true}/>)
    },
    onError: error => props.notify(<MigrationCheckToast success={false}/>),
  })

  const onMigratedButtonClick = () => {
    if (!markMigrated.isLoading) {
      markMigrated.mutate();
    }
  }

  let states: ReactElement[] = [];
  if (syncQuery.data && isMigrated && syncQuery.data.states) {
    states = syncQuery.data.states.map((state) => (<SystemSyncInfo state={state}/>))
  }


  return (
      <ToastNotification title={''} className={"enova-sync-info"} kind={"info"} hideCloseButton={true}>
        <h5>{title}</h5>
        <div className={'enova-sync-info-body'}>
          {!isMigrated && (
              <>
                <span>{t('profile-to-be-migrated')}<br/><br/></span>
                {props.showMigrationButton && (
                    <Button size="small" className={"mb-5"} onClick={onMigratedButtonClick}>
                      Oznacz profil jako zmigrowany
                      &nbsp;
                      <Loading withOverlay={false} active={!markMigrated.isIdle && markMigrated.isLoading} small={true}/>
                    </Button>
                )}
              </>
          )}
          {syncQuery.data && syncQuery.data.syncErrors.length === 0 && isMigrated && states.length === 0 && (
              <>
                {states.length === 0 ? (<span>{t('first-export-due')}</span>) : ''}
              </>
          )}
          {syncQuery.data && isMigrated && syncQuery.data.syncErrors.length > 0 && (
              <p>
                {syncQuery.data.syncErrors.map(err => (<span key={err}>{err}</span>))}
              </p>
          )}
          {states}
        </div>
      </ToastNotification>
  )

}
export default SyncInfo;