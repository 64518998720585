import CategoryTitle from "../CategoryTile/CategoryTile";
import {Preference} from "../../types/Preference";
import {PreferenceGroup} from "../../types/PreferenceTypes";
import {PreferenceSetting} from "../../types/PreferenceSetting";
import {useTranslation} from "react-i18next";

type LocalProps = {
  preferences: Preference[]|null
  onTileClick: (groupKey: PreferenceGroup) => any;
  onPreferenceClick: (pref: Preference) => any;
  openDeleteModal: (pref: Preference) => any,
  openSettingDeleteModal: (setting: PreferenceSetting) => any,
}

const CategoryTitles = (props: LocalProps) => {

  const {t} = useTranslation();

  let groups: Record<PreferenceGroup, Preference[]> = {
    [PreferenceGroup.REQUIREMENTS]: [],
    [PreferenceGroup.EXCLUSIONS]: []
  };

  if(null == props.preferences) {
    return <></>
  }

  props.preferences.forEach((preference) => {
    if (null !== preference.groupKey) {
      groups[preference.groupKey].push(preference);
    }
  })

  return (
      <div className="category-tiles bx--row mb-5">
        <CategoryTitle {...props} title={t("prefer-title")} items={groups[PreferenceGroup.REQUIREMENTS]}
                       onClick={() => props.onTileClick(PreferenceGroup.REQUIREMENTS)}/>
        <CategoryTitle {...props} title={t("exclude-title")} items={groups[PreferenceGroup.EXCLUSIONS]} onClick={() => props.onTileClick(PreferenceGroup.EXCLUSIONS)}/>
      </div>
  )
}

export default CategoryTitles