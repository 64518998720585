import {Preference, TypeBehavior} from "../Preference";

import {PreferenceFormProps} from "../PreferenceFormProps";
import {ComboBox, NumberInput, NumberInputOnChangeDataVariant} from "carbon-components-react";
import {useTranslation} from "react-i18next";

export type TransferProperties = {
  type: string | null
  maxKg: number | ""
}

type Transfer = Preference & {
  properties: TransferProperties;
}

export const TransferBehavior: TypeBehavior<Transfer> = {
  formCreator: options => (<TransferForm {...options}/>),
  marshaller: null,
  jsonReplacer: null,
  initializeProperties: (pref => pref.properties.type = 'full-transfer')
}

export default Transfer;

const TransferForm = (props: PreferenceFormProps<Transfer>) => {

  const {t} = useTranslation();

  const changeType = (type: string | null | undefined) => {
    if (type) {
      props.preference.properties.type = type;
      props.recordUpdated(props.preference);
    }
  }

  const changeNumberHandler: NumberInputOnChangeDataVariant = (ev, data) => {
    props.preference.properties.maxKg = (data.value as number | "");
    props.recordUpdated(props.preference);
  }

  return (
      <div>
        <ComboBox id={"transfer-type"} placeholder={t('type')} className={"mb-5"}
                  items={Object.keys(props.settingsLabels['type'])}
                  titleText={t('type')}
                  selectedItem={props.preference.properties.type}
                  onChange={(data) => changeType(data.selectedItem)}
                  itemToString={(item) => item ? props.settingsLabels['type'][item] : ""}
                  {...props.validation.getAttributes('settings.type')}
        />
        <NumberInput id={'weight'}
                     value={props.preference.properties.maxKg}
                     required={false}
                     invalid={false}
                     placeholder={"Max [KG]"}
                     invalidText="To nie jest poprawna liczba" label={"Waga max w KG"} min={0} max={200}
                     onChange={changeNumberHandler} allowEmpty={true}/>
      </div>
  )
}