import axios, {AxiosRequestHeaders, AxiosResponse} from 'axios';
import {Preference, PreferenceMarshaller} from "../types/Preference";
import {getBehaviorByPref, PreferenceType} from "../types/PreferenceTypes";
import {toInsertVector} from "../types/PreferenceMethods";
import useMsalToken from "./useMsalToken";
import {PreferenceSetting} from "../types/PreferenceSetting";
import {OptionsData} from "./useOptions";
import {SyncStateData} from "./useSyncState";

const baseUrl = process.env.REACT_APP_PREFERENCES_API_BASE_URL;

const apiClient = axios.create({
  baseURL: baseUrl,
  responseType: 'json',
  headers: {
    "Accept": "application/json",
    'Content-Type': 'application/json'
  },
});

const useApi = () => {
  const tokenProvider = useMsalToken();

  apiClient.interceptors.request.use(async (config) => {
    const accessToken = await tokenProvider.ensureToken();
    (config.headers as AxiosRequestHeaders).Authorization = `Bearer ${accessToken}`;

    const params = (new URL(document.location.toString())).searchParams;
    const lang = params.get('lang');
    const targetLang = lang ? lang : 'pl_PL';
    if (undefined === config.params || null === config.params) {
      config.params = {
        lang: targetLang
      }
    } else if (typeof config.params === 'object') {
      config.params['lang'] = targetLang;
    }

    return config;
  });

  return {
    save: (pref: Preference) => {
      let replacer = undefined;
      const prefBehavior = getBehaviorByPref(pref);
      if (prefBehavior.jsonReplacer !== null) {
        replacer = prefBehavior.jsonReplacer;
      }
      const insertVector = toInsertVector(pref);
      const json = JSON.stringify(insertVector, replacer);

      if (null !== pref.id) {
        return apiClient.put(`/frontend/v1/carers/${pref.carerId}/preferences/${pref.id}`, json);
      }

      return apiClient.post(`/frontend/v1/carers/${pref.carerId}/preferences`, json);
    },
    delete: (pref: Preference) => {
      return apiClient.delete(`/frontend/v1/carers/${pref.carerId}/preferences/${pref.id}`)
    },
    get: (carerId: string) => {
      return apiClient.get(`/frontend/v1/carers/${carerId}/preferences`).then((response) => {
        return response.data.preferences.map(PreferenceMarshaller);
      })
    },
    getTypes: (): Promise<PreferenceType[]> => {
      return apiClient.get('/frontend/v1/preference-types').then((response) => response.data.types);
    },
    getOptions: (): Promise<OptionsData> => {
      return apiClient.get(`/frontend/v1/options`).then((response) => response.data);
    },
    deleteSetting(setting: PreferenceSetting) {
      return apiClient.delete(`/frontend/v1/preferences/${setting.prefId}/${setting.settingKey}/${setting.valueKey}`)
    },
    markMigrated: (carerId: string) => {
      return apiClient.post(`/frontend/v1/carers/${carerId}/profile/mark-migrated`)
          .then((response: AxiosResponse<SyncStateData>) => response.data);
    },
    getEnovaSyncState: (carerId: string): Promise<SyncStateData> => {
      return apiClient.get(`/frontend/v1/carers/${carerId}/enova-sync-state`)
          .then((response: AxiosResponse<SyncStateData>) => response.data);
    }
  }
}

export default useApi;