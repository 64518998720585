import {useQuery} from "react-query";
import useApi from "./useApi";

export default function usePreferences(carerId: string) {

  const api = useApi();

  const query = useQuery(['prefs', carerId], () => api.get(carerId), {
    refetchOnWindowFocus: false,
    enabled: true,
  });

  return {
    preferences: query.data,
    isLoading: query.isLoading
  }
}
