import {Preference, TypeBehavior} from "../Preference";
import {ImmutableMap} from "../ImmutableMap";
import {PreferenceFormProps} from "../PreferenceFormProps";
import PreferenceChecks from "../../components/PreferenceChecks/PreferenceChecks";

type MedicalEquipmentProperties = {
  checked: ImmutableMap<string, boolean>;
}

type MedicalEquipment = Preference & {
  properties: MedicalEquipmentProperties
};

export const MedicalEquipmentTypeBehavior: TypeBehavior<MedicalEquipment> = {
  formCreator: (options => <MedicalEquipmentForm {...options} />),
  marshaller: ((pref, data) => {
    pref.properties.checked = ImmutableMap.selectedFromKeys(data.properties?.checked ?? []);
  }),
  jsonReplacer: null,
  initializeProperties: pref => pref.properties.checked = new ImmutableMap<string, boolean>([])
}

export default MedicalEquipment;

const MedicalEquipmentForm = (props: PreferenceFormProps<MedicalEquipment>) => {
  return (
      <>
        <PreferenceChecks {...props} settingKey={'checked'} fieldsLabel={'Zaznacz wymagany sprzęt medyczny'}
                          recordUpdated={props.recordUpdated as (pref: Preference) => any}/>
      </>
  )
}