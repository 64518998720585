import {FormCreatorOptions, Preference, TypeBehavior} from "../Preference";
import {TextInput} from "carbon-components-react";
import {ChangeEvent} from "react";

export type TandemProperties = {
  person: string
}

type Tandem = Preference & {
  properties: TandemProperties
}

export const TandemBehavior: TypeBehavior<Tandem> = {
  formCreator: (options => <TandemForm {...options} />),
  marshaller: null,
  jsonReplacer: null,
  initializeProperties: pref => {
    pref.properties.person = "";
  }
}

export default TandemBehavior;


const TandemForm = (props: FormCreatorOptions<Tandem>) => {

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    props.preference.properties.person = event.target.value;
    props.recordUpdated(props.preference);
  }

  return (
      <TextInput id={'person'} labelText={'Imię i nazwisko'} value={props.preference.properties.person} onChange={onChange}/>
  )
}