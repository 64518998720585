import {Preference, TypeBehavior} from "../Preference";
import {ImmutableMap} from "../ImmutableMap";
import {PreferenceFormProps} from "../PreferenceFormProps";
import {LandMap} from "../../components/LandMap/LandMap";

export type LandProperties = {
  targets: ImmutableMap<string, boolean>
}

type Lands = Preference & {
  properties: LandProperties;
}

export const extractLandNames = (pref: Lands): Array<string> => {
  return pref.properties.targets.getSelectedKeys();
}

export const LandsBehavior: TypeBehavior<Lands> = {
  formCreator: (options => <LandForm {...options}/>),
  jsonReplacer: null,
  marshaller: ((pref, data) => {
    pref.properties.targets = ImmutableMap.selectedFromKeys(data.properties.targets);
  }),
  initializeProperties: pref => pref.properties.targets = new ImmutableMap<string, boolean>([]),
}

export default Lands;

const LandForm = (props: PreferenceFormProps<Lands>) => {

  const onSelect = (target: string) => {
    const newValue = !props.preference.properties.targets.get(target);
    props.preference.properties.targets = props.preference.properties.targets.set(target, newValue);
    props.recordUpdated(props.preference);
  }

  return (
      <div>
        <LandMap activeTargets={props.preference.properties.targets} targetClicked={onSelect}/>
      </div>
  )
}