export class ImmutableMap<K, V> {
  internalMap: Map<K, V>;

  constructor(entries: [K, V][]) {
    this.internalMap = new Map<K, V>(entries);
  }

  set(key: K, value: V): ImmutableMap<K, V> {
    let mapCopy = new Map(this.internalMap);
    mapCopy.set(key, value);

    return new ImmutableMap<K, V>(Array.from(mapCopy));
  }

  delete(key: K) {
    let mapCopy = new Map(this.internalMap);
    mapCopy.delete(key);

    return new ImmutableMap<K, V>(Array.from(mapCopy));
  }

  get(key: K) {
    return this.internalMap.get(key);
  }

  toArray(): [K, V][] {
    return Array.from(this.internalMap)
  }

  getSelectedKeys = (): K[] => {
    const selected = this.toArray().filter(([key, value]) => value);

    return selected.map(([key, value]) => key);
  }

  static selectedFromKeys = function <K>(keys: []): ImmutableMap<K, boolean> {
    type Reducer = (acc: [K, boolean][], curr: K) => [K, boolean][];
    const reduce: Reducer = (acc, curr) => {
      acc.push([curr, true]);
      return acc;
    }
    const entries = keys.reduce(reduce, []);

    return new ImmutableMap<K, boolean>(entries)
  }

  toJSON() {
    return this.getSelectedKeys();
  }
}