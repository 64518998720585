import {DefaultPreferenceBehavior, Preference, TypeBehavior} from "./Preference";
import {TransferBehavior} from "./PrefTypes/Transfer";
import {LocalizationBehavior} from "./PrefTypes/LocalizationType";
import {LandsBehavior} from "./PrefTypes/Land";
import {DiseasesBehavior} from "./PrefTypes/Diseases";
import {MaxHeightBehavior} from "./PrefTypes/MaxHeight";
import {MaxWeightBehavior} from "./PrefTypes/MaxWeight";
import {MobilityBehavior} from "./PrefTypes/Mobility";
import {HousingTypeBehavior} from "./PrefTypes/HousingType";
import {GenderBehavior} from "./PrefTypes/Gender";
import {DutiesTypeBehavior} from "./PrefTypes/Duties";
import {FacilitiesTypeBehavior} from "./PrefTypes/Facilities";
import {HygieneTypeBehavior} from "./PrefTypes/Hygiene";
import {MinimalSalaryBehavior} from "./PrefTypes/MinimalSalary";
import {NightShiftsInWeekBehavior} from "./PrefTypes/NightShiftsInWeek";
import {MinimalBudgetBehavior} from "./PrefTypes/MinimalBudget";
import {MedicalEquipmentTypeBehavior} from "./PrefTypes/MedicalEquipment";
import {DrivingTypeBehavior} from "./PrefTypes/Driving";
import {PostalCodesTypeBehavior} from "./PrefTypes/PostalCodes";
import {MapLocationTypeBehavior} from "./PrefTypes/MapLocationType";
import MaxAgeBehavior from "./PrefTypes/MaxAge";
import TandemBehavior from "./PrefTypes/TandemBehavior";

export enum PreferenceGroup {
  REQUIREMENTS=1,
  EXCLUSIONS=2,
}

export type PreferenceTemplate = {
  typeKey: number,
  properties: any
}

export type PreferencePreset = {
  label: string,
  preferenceTemplate: PreferenceTemplate
}

export type PreferenceType = {
  label: string,
  id: PreferenceTypeKey,
  hasFormElement: boolean,
  presets: PreferencePreset[]
}

export enum PreferenceTypeKey {
  Transfer = 1,
  Localization = 2,
  Land = 3,
  Diseases = 4,
  HousingType = 8,
  MaxHeight = 12,
  MaxWeight = 13,
  Mobility = 14,
  Gender = 15,
  Duties = 18,
  Facilities = 19,
  Hygiene = 20,
  MinimalSalary = 22,
  NightShiftsInWeeek = 23,
  MinimalBudget = 24,
  MedicalEquipment = 25,
  Driving = 26,
  PostalCodes = 27,
  MapLocation = 28,
  MaxAge = 29,
  Tandem = 30,
}

const BehaviorsMap: Record<number | PreferenceTypeKey, TypeBehavior<any>> = {
  [PreferenceTypeKey.Transfer]: TransferBehavior,
  [PreferenceTypeKey.Localization]: LocalizationBehavior,
  [PreferenceTypeKey.Land]: LandsBehavior,
  [PreferenceTypeKey.Diseases]: DiseasesBehavior,
  [PreferenceTypeKey.MaxHeight]: MaxHeightBehavior,
  [PreferenceTypeKey.MaxWeight]: MaxWeightBehavior,
  [PreferenceTypeKey.Mobility]: MobilityBehavior,
  [PreferenceTypeKey.Gender]: GenderBehavior,
  [PreferenceTypeKey.HousingType]: HousingTypeBehavior,
  [PreferenceTypeKey.Duties]: DutiesTypeBehavior,
  [PreferenceTypeKey.Facilities]: FacilitiesTypeBehavior,
  [PreferenceTypeKey.Hygiene]: HygieneTypeBehavior,
  [PreferenceTypeKey.MinimalSalary]: MinimalSalaryBehavior,
  [PreferenceTypeKey.NightShiftsInWeeek]: NightShiftsInWeekBehavior,
  [PreferenceTypeKey.MinimalBudget]: MinimalBudgetBehavior,
  [PreferenceTypeKey.MedicalEquipment]: MedicalEquipmentTypeBehavior,
  [PreferenceTypeKey.Driving]: DrivingTypeBehavior,
  [PreferenceTypeKey.PostalCodes]: PostalCodesTypeBehavior,
  [PreferenceTypeKey.MapLocation]: MapLocationTypeBehavior,
  [PreferenceTypeKey.MaxAge]: MaxAgeBehavior,
  [PreferenceTypeKey.Tandem]: TandemBehavior
}

export const getBehavior = (typeKey: PreferenceTypeKey): TypeBehavior<any> => {
  if (BehaviorsMap[typeKey]) {
    return BehaviorsMap[typeKey];
  }

  return DefaultPreferenceBehavior;
}

export const getBehaviorByPref = (pref: Preference): TypeBehavior<any> => {
  return getBehavior(pref.associatedType.id);
}