import {Preference, TypeBehavior} from "../Preference";
import {ImmutableMap} from "../ImmutableMap";
import {PreferenceFormProps} from "../PreferenceFormProps";
import PreferenceChecks from "../../components/PreferenceChecks/PreferenceChecks";
import {useTranslation} from "react-i18next";

type DrivingProperties = {
  exclusions: ImmutableMap<string, boolean>;
}

type Driving = Preference & {
  properties: DrivingProperties
};

export const DrivingTypeBehavior: TypeBehavior<Driving> = {
  formCreator: (options => <DrivingForm {...options} />),
  marshaller: ((pref, data) => {
    pref.properties.exclusions = ImmutableMap.selectedFromKeys(data.properties?.exclusions ?? []);
  }),
  jsonReplacer: null,
  initializeProperties: pref => pref.properties.exclusions = new ImmutableMap<string, boolean>([])
}

export default Driving;

const DrivingForm = (props: PreferenceFormProps<Driving>) => {
  const {t} = useTranslation();

  return (
      <>
        <PreferenceChecks {...props} settingKey={'exclusions'} fieldsLabel={t('exclusions')}
                          recordUpdated={props.recordUpdated as (pref: Preference) => any}/>
      </>
  )
}