import React, {ReactElement, useState} from 'react';
import './App.scss';
import {Content} from 'carbon-components-react';
import AppHeader from './components/AppHeader/AppHeader';
import {Route, Routes} from 'react-router-dom';
import PreferencesPage from './pages/PreferencesPage';
import {QueryClient, QueryClientProvider} from 'react-query'
import {MsalAuthenticationTemplate} from '@azure/msal-react';
import {InteractionType} from "@azure/msal-browser";
import { ReactQueryDevtools } from 'react-query/devtools'

function App() {

  const queryClient = new QueryClient()

  return (
      <>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <AppHeader/>
          <Content>
            <Routes>
              <Route path="/:carerId/:firstname/:lastname/:migrated" element={
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                  <PreferencesPage />
                </MsalAuthenticationTemplate>
              }/>
            </Routes>

          </Content>
        </QueryClientProvider>
      </>
  );
}

export default App;
