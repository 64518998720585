import {Preference, TypeBehavior} from "../Preference";
import {PreferenceFormProps} from "../PreferenceFormProps";
import {Tag} from "carbon-components-react";
import {ImmutableMap} from "../ImmutableMap";
import PostalCodeInput from "../../components/PostalCodeInput/PostalCodeInput";
import * as React from "react";
import {useTranslation} from "react-i18next";

type PostalCodesProperties = {
  selected: ImmutableMap<number, boolean>;
}

type PostalCodes = Preference & {
  properties: PostalCodesProperties
};

export const PostalCodesTypeBehavior: TypeBehavior<PostalCodes> = {
  formCreator: (options => <PostalCodesForm {...options} />),
  marshaller: ((pref, data) => {
    pref.properties.selected = ImmutableMap.selectedFromKeys(data.properties?.selected ?? []);
  }),
  jsonReplacer: null,
  initializeProperties: pref => pref.properties.selected = new ImmutableMap<number, boolean>([]),
}

const PostalCodesForm = (props: PreferenceFormProps<PostalCodes>) => {

  const {t} = useTranslation();

  const onRemoveCode = (code: number) => {
    props.preference.properties.selected = props.preference.properties.selected.delete(code);
    props.recordUpdated(props.preference);
  };

  const pills = props.preference.properties.selected.getSelectedKeys().map(
      (code) => <PostalCodePill code={code} onRemove={onRemoveCode}/>
  );

  const onAddCode = (code: number) => {
    props.preference.properties.selected = props.preference.properties.selected.set(code, true);
    props.recordUpdated(props.preference);
  }

  return (
      <>
        <p>{t('selected')}</p>
        <div className="p-5">
          {pills.length > 0 ? pills : t('postal-code-not-selected')}
        </div>
        <PostalCodeInput onAdd={onAddCode}/>
        <input type={'hidden'} data-invalid={props.validation.hasError('settings.selected')}/>
        <div className={"bx--form-requirement"}>
          {props.validation.getError('settings.selected')}
        </div>
      </>
  )
}

type PillProps = {
  code: number
  onRemove: (code: number) => any,
}

const PostalCodePill = (props: PillProps) => {
  return (
      <Tag key={props.code} filter={true} onClose={() => props.onRemove(props.code)}>
        {props.code}
      </Tag>
  )
}