import {Tag, TagTypeName} from "carbon-components-react";
import {Preference} from "../../types/Preference";
import {PreferenceGroup} from "../../types/PreferenceTypes";
import {ReactElement} from "react";
import {ThumbsDown16, ThumbsUp16} from "@carbon/icons-react";
import {PreferenceSetting} from "../../types/PreferenceSetting";

type LocalProps = {
  pref: Preference,
  onPreferenceClick: (pref: Preference) => any,
  openDeleteModal: (pref: Preference) => any,
  openSettingDeleteModal: (setting: PreferenceSetting) => any,
}

const colorTypes: Record<PreferenceGroup, TagTypeName> = {
  [PreferenceGroup.EXCLUSIONS]: "red",
  [PreferenceGroup.REQUIREMENTS]: "blue"
}

const icons: Record<PreferenceGroup, ReactElement> = {
  [PreferenceGroup.EXCLUSIONS]: <ThumbsDown16/>,
  [PreferenceGroup.REQUIREMENTS]: <ThumbsUp16/>
}

const getType = (pref: Preference): TagTypeName => {
  return pref.groupKey === null ? "gray" : colorTypes[pref.groupKey];
}

const getIcon = (pref: Preference): null | ReactElement => {
  return pref.groupKey === null ? null : icons[pref.groupKey];
}

const PreferencePills = (props: LocalProps) => {

  const onTagClick = (ev: any) => {
    ev.preventDefault();
    ev.stopPropagation();
    props.onPreferenceClick(props.pref)
  }

  const onPrefDeleteRequest = () => {
    props.openDeleteModal(props.pref);
  }

  const createPill = (pref: Preference, text: string, onClose: () => any) => (
      <Tag key={pref.id + text} size="md" onClick={(event) => onTagClick(event)} type={getType(props.pref)} filter={true} onClose={onClose}>
        <div className="pref-tag">
          {getIcon(props.pref)}
          {text}
        </div>
      </Tag>
  )

  let pills = [createPill(props.pref, props.pref.textSummary, onPrefDeleteRequest)];
  if (props.pref.settingsSpread.length > 0) {
    pills = props.pref.settingsSpread.map((setting) => createPill(props.pref, setting.label, () => props.openSettingDeleteModal(setting)));
  }

  return (
      <>
        {pills}
      </>
  );
}

export default PreferencePills;