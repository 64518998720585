import React from "react";
import {PreferenceGroup} from "../../types/PreferenceTypes";
import CategoryTiles from "../CategoryTiles/CategoryTitles";
import {ListViewProps} from "../../types/PreferenceListView";
import {PreferenceCreationContext} from "../../types/Preference";

const PreferencesGroupView = (props: ListViewProps) => {

  const onTileClick = (groupKey: PreferenceGroup) => {
    const insertContext: PreferenceCreationContext = {carerId: props.carerId, targetGroup: groupKey, targetTypeKey: null};
    props.startEditWithContext(insertContext);
  }

  return (
      <CategoryTiles {...props} onTileClick={onTileClick}/>
  )
}

export default PreferencesGroupView;