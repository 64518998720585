import {Preference, PreferenceCreationContext} from "../types/Preference";
import {useEffect, useState} from "react";

export default function useEditedPreference() {

  const [isSettingsOpen, setSettingsOpen] = useState<boolean>(false);

  const [editedPreference, setEditedPreference] = useState<Preference | null>(null);

  const [insertContext, setInsertContext] = useState<PreferenceCreationContext | null>(null);

  const resetEditPref = () => {
    setInsertContext(null);
    setEditedPreference(null);
  }

  useEffect(
      () => setSettingsOpen(editedPreference !== null || insertContext !== null),
      [editedPreference, insertContext]
  )

  return {isSettingsOpen, editedPreference, insertContext, setEditedPreference, setInsertContext, resetEditPref}
}