import {Preference, TypeBehavior} from "../Preference";
import {ImmutableMap} from "../ImmutableMap";
import {PreferenceFormProps} from "../PreferenceFormProps";
import PreferenceChecks from "../../components/PreferenceChecks/PreferenceChecks";
import {useTranslation} from "react-i18next";

type HousingTypeProperties = {
  checked: ImmutableMap<string, boolean>;
}

type HousingType = Preference & {
  properties: HousingTypeProperties
};

export const HousingTypeBehavior: TypeBehavior<HousingType> = {
  formCreator: (options => <HousingForm {...options} />),
  marshaller: ((pref, data) => {
    pref.properties.checked = ImmutableMap.selectedFromKeys(data.properties?.checked ?? []);
  }),
  jsonReplacer: null,
  initializeProperties: pref => pref.properties.checked = new ImmutableMap<string, boolean>([])
}

const HousingForm = (props: PreferenceFormProps<HousingType>) => {

  const {t} = useTranslation();

  return (
      <>
        <PreferenceChecks {...props} fieldsLabel={t('housing-type')} settingKey={'checked'}
                          recordUpdated={props.recordUpdated as (pref: Preference) => any}/>
      </>
  )
}