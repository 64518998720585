import {Preference, TypeBehavior} from "../Preference";
import PreferenceValueInput from "../../components/MaxInput/PreferenceValueInput";
import {PreferenceFormProps} from "../PreferenceFormProps";
import {useTranslation} from "react-i18next";

export type MinimalSalaryProperties = {
  value: number | ""
}

type MinimalSalary = Preference & {
  properties: MinimalSalaryProperties;
}

export const MinimalSalaryBehavior: TypeBehavior<MinimalSalary> = {
  formCreator: options => <MinimalSalaryForm {...options} />,
  marshaller: null,
  jsonReplacer: null,
  initializeProperties: pref => {
    pref.properties.value = "";
  }
}

export default MinimalSalary;

const MinimalSalaryForm = (options: PreferenceFormProps<MinimalSalary>) => {
  const {t} = useTranslation();

  return (
      <PreferenceValueInput {...options} placeholder={t('value-required')} label={t('minimal-salary-label')}/>
  )
}