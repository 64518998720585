import {Preference, TypeBehavior} from "../Preference";
import {PreferenceFormProps} from "../PreferenceFormProps";
import {Wrapper} from "@googlemaps/react-wrapper";
import {Button, Slider, TextInput} from "carbon-components-react";
import {KeyboardEventHandler, useCallback, useEffect, useState} from "react";
import * as React from "react";
import {useTranslation} from "react-i18next";

type MapLocationTypePreferences = {
  lat: number | null,
  lng: number | null,
  range: number,
  placeName: string | null,
}

type MapLocation = Preference & {
  properties: MapLocationTypePreferences;
}

export const MapLocationTypeBehavior: TypeBehavior<MapLocation> = {
  formCreator: (options => <MapLocationForm {...options} />),
  marshaller: null,
  jsonReplacer: null,
  initializeProperties: pref => {
    pref.properties.placeName = null;
    pref.properties.lat = null;
    pref.properties.lng = null;
    pref.properties.range = 0;
  }
}

type MapProps = {
  lat: number,
  lng: number,
  radius: number
  showMarker: boolean,
}

const MapComponent = (props: MapProps) => {

  const [map, setMap] = useState<google.maps.Map | null>(null);

  const [circle, setCircle] = useState<google.maps.Circle | null>(null);

  const [marker, setMarker] = useState<google.maps.Marker | null>(null);

  const center = {lat: props.lat, lng: props.lng};

  // false flag for missing center dependency, i dont want it to change map when center changes!
  const mapRef = useCallback(node => {
    if (node !== null) {
      const map = new window.google.maps.Map(node, {center: center, zoom: 6});
      setMap(map);
    }
  }, [])


  useEffect(() => {
    if (map === null) {
      return;
    }
    const center = {lat: props.lat, lng: props.lng};
    map.setCenter(center)
  }, [map])


  useEffect(() => {
    if (map === null) {
      return;
    }
    if (marker !== null) {
      marker.setMap(null);
    }
    if (props.showMarker) {
      const newMarker = new window.google.maps.Marker({position: center, map,});
      setMarker(newMarker);
    }

    if (null !== circle) {
      circle.setMap(null);
    }
    const newCircle = new google.maps.Circle({
      strokeColor: "#FF0000", strokeOpacity: 0.8, strokeWeight: 2, fillColor: "#FF0000",
      fillOpacity: 0.35, map, center: center, radius: props.radius * 1000,
    });
    setCircle(newCircle);

  }, [props, map]);

  return <div ref={mapRef} id="map" className={"google-maps-wrapper"}/>;
}

type MapSettingsProps = {
  initSearch: string | null,
  initRadius: number,
  initLocation: { lat: number, lng: number, text: string } | null,
  onSubmit: (search: string, lat: number, lng: number, radius: number) => any
}

const MapSettings = (props: MapSettingsProps) => {

  const {t} = useTranslation();

  const [selectedRadius, setSelectedRadius] = useState<number>(props.initRadius);

  const [searchText, setSearchText] = useState<string>(props.initSearch ?? "");

  const [submittedSearchText, setSubmittedSearchText] = useState<string | null>(props.initSearch);

  const [searchResult, setSearchResult] = useState<{ lat: number, lng: number, text: string } | null>(props.initLocation);

  useEffect(() => {
    if (null === submittedSearchText || submittedSearchText.length === 0 || window.google === undefined) {
      return;
    }
    const geocoder = new google.maps.Geocoder();
    const request: google.maps.GeocoderRequest = {
      'address': submittedSearchText,
      'region': 'de',
    }
    console.log('running geocoding');
    geocoder.geocode(request, (results, status) => {
      if (status === "OK" && null !== results) {
        const location = results[0].geometry.location;
        setSearchResult({lat: location.lat(), lng: location.lng(), text: results[0].address_components[0].long_name});
      } else {
        alert('Geocode was not successful for the following reason: ' + status);
      }
    })
  }, [submittedSearchText]);

  useEffect(() => {
    if (searchResult !== null) {
      props.onSubmit(searchResult.text, searchResult.lat, searchResult.lng, selectedRadius)
    }
  }, [selectedRadius, searchResult])


  const onSearchPlaceButtonClick = () => {
    setSubmittedSearchText(searchText);
  }
  const checkSubmit: KeyboardEventHandler = (event) => {
    if (event.key === 'Enter') {
      setSubmittedSearchText(searchText);
      event.preventDefault();
      event.stopPropagation();
    }
  }

  return (
      <>
        <div className="bx--row bx--flex-end mb-5">
          <div className="bx--col-lg-6">
            <TextInput labelText={t('search') as string} value={searchText} id={'city-search'}
                       onChange={event => setSearchText(event.target.value)} onKeyDown={checkSubmit}/>
          </div>
          <div className="bx--col-lg-3">
            <Button onClick={() => onSearchPlaceButtonClick()}>{t('set')}</Button>
          </div>
        </div>
        <div className="bx--row mb-5">
          <div className="bx--col-lg-8">
            <Slider id="slider" labelText={`${t('range')} [km]`} max={1700} min={0} step={10} value={selectedRadius}
                    onChange={(value) => setSelectedRadius(value.value)}/>
          </div>
        </div>
      </>
  )
}

const MapLocationForm = (props: PreferenceFormProps<MapLocation>) => {

  const onMapTargetChange = (search: string, lat: number, lng: number, radius: number) => {
    props.preference.properties.placeName = search;
    props.preference.properties.lat = lat;
    props.preference.properties.lng = lng;
    props.preference.properties.range = radius;
    props.recordUpdated(props.preference);
  }

  let initLocation = null;
  if (null !== props.preference.properties.lat && null !== props.preference.properties.lng) {
    initLocation = {lat: props.preference.properties.lat, lng: props.preference.properties.lng, text: props.preference.properties.placeName ?? ""};
  }

  return (
      <div>
        <MapSettings
            onSubmit={onMapTargetChange}
            initSearch={props.preference.properties.placeName}
            initRadius={props.preference.properties.range}
            initLocation={initLocation}
        />
        <input type={'hidden'} data-invalid={props.validation.hasError('settings.placeName')}/>
        <p className={"bx--form-requirement"}>
          {props.validation.getError('settings.placeName')}
        </p>
        <Wrapper apiKey={process.env.REACT_APP_PREFERENCES_GOOGLE_MAPS_API_KEY as string}>
          <MapComponent
              lat={props.preference.properties.lat ?? 52.520008}
              lng={props.preference.properties.lng ?? 13.404954}
              radius={props.preference.properties.range}
              showMarker={(props.preference.properties.lat !== null && props.preference.properties.lng !== null)}
          />
        </Wrapper>
      </div>
  )
};