import {Preference, TypeBehavior} from "../Preference";
import {ImmutableMap} from "../ImmutableMap";
import {PreferenceFormProps} from "../PreferenceFormProps";
import PreferenceChecks from "../../components/PreferenceChecks/PreferenceChecks";
import {useTranslation} from "react-i18next";

export type LocalizationProperties = {
  checked: ImmutableMap<string, boolean>
}

type LocalizationType = Preference & {
  properties: LocalizationProperties;
}

export const LocalizationBehavior: TypeBehavior<LocalizationType> = {
  marshaller: ((pref, data) => {
    pref.properties.checked = ImmutableMap.selectedFromKeys(data.properties?.checked ?? []);
  }),
  formCreator: (options => <LocalizationTypeForm {...options} />),
  jsonReplacer: null,
  initializeProperties: pref => pref.properties.checked = new ImmutableMap<string, boolean>([])
}

export default LocalizationType;

const LocalizationTypeForm = (props: PreferenceFormProps<LocalizationType>) => {
  const {t} = useTranslation();

  return (
      <>
        <PreferenceChecks {...props} fieldsLabel={t('type')} settingKey={'checked'}
                          recordUpdated={props.recordUpdated as (pref: Preference) => any}/>
      </>
  )
}