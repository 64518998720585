import {useState} from "react";
import {Button, NumberInput} from "carbon-components-react";
import {useTranslation} from "react-i18next";

type LocalProps = {
  onAdd: (code: number) => any,
}
const PostalCodeInput = (props: LocalProps) => {
  const {t} = useTranslation();

  const [code, setCode] = useState<number | "">("");

  const addCode = () => {
    if (code === "") {
      return;
    }
    props.onAdd(code);
    setCode("");
  }

  return (
      <div className="bx--row">
        <div className="bx--col-lg-4">
          <NumberInput id={'new-code'} value={code} onChange={(ev: any, data: any) => setCode(data.value as number | "")} invalid={false} allowEmpty={true}/>
        </div>
        <div className="bx--col-lg-4">
          <Button onClick={addCode}>{t('add')}</Button>
        </div>
      </div>
  )
}

export default PostalCodeInput;