import {Preference, TypeBehavior} from "../Preference";
import PreferenceValueInput from "../../components/MaxInput/PreferenceValueInput";

export type MaxWeightProperties = {
  value: number | ""
}

type MaxWeight = Preference & {
  properties: MaxWeightProperties
}

export const MaxWeightBehavior: TypeBehavior<MaxWeight> = {
  formCreator: options => (<><PreferenceValueInput {...options} placeholder={'Max'} label={'Max'}/></>),
  marshaller: null,
  jsonReplacer: null,
  initializeProperties: pref => {
    pref.properties.value = "";
  }
}

export default MaxWeight;