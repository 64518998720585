import React, {ReactElement, useState} from "react";
import {Preference} from "../types/Preference";
import EditModal from "../components/EditModal/EditModal";
import PreferencesGroupView from "../components/PreferencesGroupView/PreferencesGroupView";
import {Loading} from "carbon-components-react";
import {useParams} from "react-router";
import {ListViewProps} from "../types/PreferenceListView";
import usePreferences from "../hooks/usePreferences";
import useEditedPreference from "../hooks/useEditedPreference";
import useDeleteModal from "../hooks/useDeleteModal";
import PreferenceDeleteModal from "../components/PreferenceDeleteModal/PreferenceDeleteModal";
import SaveFailureNotify from "../components/Notifications/SaveFailureNotify";
import useOptions from "../hooks/useOptions";
import PreferenceSettingDeleteModal from "../components/PreferenceSettingDeleteModal/PreferenceSettingDeleteModal";
import {PreferenceSetting} from "../types/PreferenceSetting";
import SyncInfo from "../components/SyncInfo/SyncInfo";

type LocalProps = {}

const PreferencesPage = (props: LocalProps) => {

  let {carerId, firstname, lastname, migrated} = useParams<{ carerId: string, firstname: string, lastname: string, migrated: string }>();

  const {preferences, isLoading} = usePreferences(carerId as string);

  const {editedPreference, insertContext, setEditedPreference, setInsertContext, isSettingsOpen, resetEditPref} = useEditedPreference();

  const deletePreferenceCtx = useDeleteModal<Preference>();

  const deletePrefSettingCtx = useDeleteModal<PreferenceSetting>();

  const [toaster, fillToaster] = useState<ReactElement | null>(null);

  const onPrefSaveFailure = () => fillToaster(<SaveFailureNotify key={Math.random().toString(2)}/>)

  const optionsQuery = useOptions();

  const viewProps: ListViewProps = {
    types: optionsQuery.data?.types ?? [],
    carerId: carerId as string,
    preferences: preferences,
    onPreferenceClick: (pref: Preference): void => setEditedPreference(pref),
    enableEditContext: (pref: Preference) => setEditedPreference(pref),
    startEditWithContext: context => setInsertContext(context),
    openDeleteModal: (pref: Preference) => deletePreferenceCtx.set(pref),
    openSettingDeleteModal: (setting) => deletePrefSettingCtx.set(setting)
  }

  return (
      <>
        {(editedPreference || insertContext) && (
            <EditModal
                isOpen={isSettingsOpen}
                pref={editedPreference}
                insertContext={insertContext}
                selectableData={optionsQuery.data ?? null}
                onClose={resetEditPref}
                onSubmitSuccess={resetEditPref}
                onSubmitFailure={onPrefSaveFailure}
                types={optionsQuery.data?.types ?? []}
                settingsLabels={optionsQuery.data?.settingsLabels ?? {}}
            />
        )}
        <PreferenceDeleteModal
            {...deletePreferenceCtx}
            onSuccess={() => deletePreferenceCtx.reset()}
            onAbort={() => deletePreferenceCtx.reset()}
        />
        <PreferenceSettingDeleteModal
            {...deletePrefSettingCtx}
            onSuccess={() => deletePrefSettingCtx.reset()}
            onAbort={() => deletePrefSettingCtx.reset()}
        />
        <div className={"bx--grid bx--grid--full-width"}>
          <div className="preferences-list-title mb-7">
            <div className="name">
              <h2 className={"main-welcome mr-5"}>{firstname} {lastname}</h2>
              <Loading description="Active loading indicator" withOverlay={false} active={isLoading} small={true}/>
            </div>
            <div>
            </div>
          </div>
          <PreferencesGroupView {...viewProps}/>
          <div>
            <SyncInfo
                carerId={carerId as string}
                notify={fillToaster}
                showMigrationButton={false}/>
          </div>
        </div>
        <div>{toaster}</div>
      </>
  );
}
export default PreferencesPage;