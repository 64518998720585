import {Tile, ClickableTile} from "carbon-components-react";
import {AddFilled24} from "@carbon/icons-react";
import PreferencePills from "../PreferencePill/PreferencePills";
import {Preference} from "../../types/Preference";
import {PreferenceSetting} from "../../types/PreferenceSetting";

type LocalProps = {
  title: string,
  items: Preference[],
  onClick: () => any,
  onPreferenceClick: (pref: Preference) => any,
  openDeleteModal: (pref: Preference) => any
  openSettingDeleteModal: (setting: PreferenceSetting) => any
}

const CategoryTile = (props: LocalProps) => {

  const tags = props.items.map((item, key) => (
      <PreferencePills key={item.id} pref={item} {...props}/>)
  );

  return (
      <div className="bx--col-lg-8">
        <Tile className={"category-tile p-0"}>
          <ClickableTile onClick={props.onClick} className={`category-tile-title`}>
            <h4>{props.title}</h4>
            <AddFilled24/>
          </ClickableTile>
          <Tile className={"category-tile"}>
            {tags}
          </Tile>
        </Tile>
      </div>
  )
}


export default CategoryTile