import useApi from "./useApi";
import {useQuery, useQueryClient} from "react-query";

export type ExportState = {
  targetSystem: string,
  lastSuccess: string | null,
  inSync: boolean,
}

export type SyncStateData = {
  syncPossible: boolean,
  syncErrors: string[],
  states: ExportState[],
  migrated: boolean,
}

export default function useSyncState(carerId: string) {

  const api = useApi();

  const queryClient = useQueryClient();

  return useQuery<SyncStateData, Error>(['enovaSyncState'], () => api.getEnovaSyncState(carerId), {
    refetchOnWindowFocus: false,
    onSuccess: (data: SyncStateData) => {
      const outOfSync = data.states.filter((s) => !s.inSync);
      if (outOfSync.length > 0) {
        setTimeout(() => {
          queryClient.invalidateQueries('enovaSyncState');
        }, 2500)
      }
    }
  });
}