import {Configuration} from '@azure/msal-browser';

/**
 * App-wide instance of configuration settings
 * @see Configuration
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_PREFERENCES_O365_APP_CLIENT_ID as string,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_PREFERENCES_O365_TENANT_ID}`,
    redirectUri: process.env.REACT_APP_PREFERENCES_O365_APP_REDIRECT_URI as string,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['App.Use'],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};