import {Preference, TypeBehavior} from "../Preference";
import {ImmutableMap} from "../ImmutableMap";
import {PreferenceFormProps} from "../PreferenceFormProps";
import PreferenceChecks from "../../components/PreferenceChecks/PreferenceChecks";
import {useTranslation} from "react-i18next";

type DutiesProperties = {
  checked: ImmutableMap<string, boolean>;
}

type Duties = Preference & {
  properties: DutiesProperties
};

export const DutiesTypeBehavior: TypeBehavior<Duties> = {
  formCreator: (options => <DutiesForm {...options} />),
  marshaller: ((pref, data) => {
    pref.properties.checked = ImmutableMap.selectedFromKeys(data.properties?.checked ?? []);
  }),
  jsonReplacer: null,
  initializeProperties: pref => pref.properties.checked = new ImmutableMap<string, boolean>([])
}

export default Duties;

const DutiesForm = (props: PreferenceFormProps<Duties>) => {

  const {t} = useTranslation();

  return (
      <>
        <PreferenceChecks {...props} settingKey={'checked'} fieldsLabel={t('select')}
                          recordUpdated={props.recordUpdated as (pref: Preference) => any}/>
      </>
  )
}