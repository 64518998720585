import {ComboBox} from 'carbon-components-react';
import {getBehaviorByPref, PreferenceType, PreferencePreset} from '../../types/PreferenceTypes';
import {Preference, PreferenceCreationContext} from "../../types/Preference";
import {useEffect, useState} from "react";
import {Selectable, OptionsData} from "../../hooks/useOptions";
import {useTranslation} from "react-i18next";

type LocalProps = {
  types: PreferenceType[],
  selectableData: OptionsData,
  insertContext: PreferenceCreationContext,
  onPrefTypeChange: (pref: Preference | null) => any,
}

const PreferenceTypeInput = (props: LocalProps) => {

  const {t} = useTranslation();

  const [selected, setSelected] = useState<Selectable | null>(null);

  const onTypeSelect = (selected: Selectable | undefined | null) => {
    setSelected(selected ?? null);
  }

  useEffect(() => {
    let newProp: Preference | null = null;
    if (selected !== null && selected !== undefined) {

      if ((selected as PreferenceType).id) {
        const preferenceType = (selected as PreferenceType);
        newProp = {
          id: null,
          carerId: props.insertContext.carerId,
          groupKey: props.insertContext.targetGroup,
          textSummary: '',
          properties: {},
          negotiability: false,
          associatedType: preferenceType,
          settingsSpread: []
        }
        const behavior = getBehaviorByPref(newProp);
        if (behavior.initializeProperties !== null) {
          behavior.initializeProperties(newProp);
        }
      } else if ((selected as PreferencePreset).preferenceTemplate) {
        const preset = (selected as PreferencePreset);

        const presetType = props.types.find((value) => value.id === preset.preferenceTemplate.typeKey);
        if (!presetType) {
          throw new Error('Cannot find type for  preset with type id: ' + preset.preferenceTemplate.typeKey);
        }

        newProp = {
          id: null,
          carerId: props.insertContext.carerId,
          groupKey: props.insertContext.targetGroup,
          textSummary: '',
          properties: Object.assign({}, preset.preferenceTemplate.properties),
          negotiability: false,
          associatedType: presetType,
          settingsSpread: []
        }
        const behavior = getBehaviorByPref(newProp);
        if (behavior.initializeProperties !== null && behavior.marshaller !== null) {
          behavior.marshaller(newProp, preset.preferenceTemplate);
        }
      }
    }

    props.onPrefTypeChange(newProp);
  }, [selected])

  return (
      <ComboBox<Selectable>
          id={"type"}
          placeholder={t('pref-type')}
          items={props.selectableData.selectables ?? []}
          className={'mb-5'}
          selectedItem={selected}
          onChange={(data) => onTypeSelect(data.selectedItem)}
          shouldFilterItem={data => {
            if (data.inputValue === selected?.label) {
              return true;
            }
            return data.item.label.toUpperCase().includes(data.inputValue?.toUpperCase() ?? "")
          }}
      />
  )
}

export default PreferenceTypeInput;