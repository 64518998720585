import DeleteModal from "../DeleteModal/DeleteModal";
import React from "react";
import {PreferenceSetting} from "../../types/PreferenceSetting";
import {useMutation, useQueryClient} from "react-query";
import useApi from "../../hooks/useApi";

type LocalProps = {
  open: boolean,
  subject: PreferenceSetting | null,
  onSuccess: () => any,
  onAbort: () => any,
}

const PreferenceSettingDeleteModal = (props: LocalProps) => {

  const queryClient = useQueryClient();

  const api = useApi();

  const deletePrefSetting = useMutation((setting: PreferenceSetting) => api.deleteSetting(setting), {
    onSuccess: () => {
      queryClient.invalidateQueries('prefs');
      queryClient.invalidateQueries('enovaSyncState');
      props.onSuccess();
    },
  });


  const performDelete = () => {
    if (null !== props.subject) {
      deletePrefSetting.mutate(props.subject);
    }
  }

  if (null === props.subject) {
    return null;
  }

  return (
      <DeleteModal open={props.open} onRequestSubmit={performDelete} onRequestClose={props.onAbort} primaryButtonDisabled={deletePrefSetting.isLoading}>
        Planujesz usunąć ustawienie <span className="bold">{props.subject.label}</span> z preferencji.
        <br/>
        Czy wykonać akcje usunięcia?
      </DeleteModal>
  );

}
export default PreferenceSettingDeleteModal;