import {Preference, PreferenceCreationContext} from "../../types/Preference";
import {Form, Loading, Modal} from "carbon-components-react";
import React, {KeyboardEventHandler, useState} from "react";
import PreferenceForm from "../PreferenceForm/PreferenceForm";
import {useMutation, useQueryClient} from "react-query";
import {makePrefCopy} from "../../types/PreferenceMethods";
import useValidation from "../../hooks/useValidation";
import useApi from "../../hooks/useApi";
import PreferenceTypeInput from "../PreferenceTypeInput/PreferenceTypeInput";
import {PreferenceType} from "../../types/PreferenceTypes";
import {OptionsData} from "../../hooks/useOptions";
import {useTranslation} from "react-i18next";

type LocalProps = {
  types: PreferenceType[],
  pref: Preference | null,
  selectableData: OptionsData | null,
  insertContext: PreferenceCreationContext | null,
  isOpen: boolean;
  onClose: () => any;
  onSubmitSuccess: () => any;
  onSubmitFailure: () => any;
  // think of this as indexed array - first level is all possible preference types indexed by their id
  // second level is given single type multiple properties indexed by their key
  // thir level is just an kv array of key and translated label
  settingsLabels: Record<number, Record<string, Record<string, string>>>
}

const EditModal = (props: LocalProps) => {

  const {t} = useTranslation();

  const api = useApi();

  const queryClient = useQueryClient();

  // making copy - we dont want to modify preference, just copy. Modification is done only on modal submit
  let initialPref = null;
  if (props.pref !== null) {
    initialPref = makePrefCopy(props.pref);
  }

  const [editedPref, updateEditedPref] = useState<Preference | null>(initialPref);

  const onEditedPrefUpdate = (newState: Preference) => {
    updateEditedPref(Object.assign({}, newState));
  }

  const onPrefTypeChange = (newPref: Preference | null) => {
    if (newPref !== null && editedPref !== null) {
      newPref.negotiability = editedPref?.negotiability;
    }
    updateEditedPref(newPref);
  }

  const validation = useValidation();

  const save = useMutation((pref: Preference) => api.save(pref), {
    onSuccess: () => {
      queryClient.invalidateQueries('prefs');
      queryClient.invalidateQueries('enovaSyncState');
      props.onSubmitSuccess();
    },
    onError: ({response}, variables, context) => {
      if (response.data.hasOwnProperty('violations')) {
        validation.setAll(response.data.violations);
      } else {
        props.onSubmitFailure();
      }
    },
  })

  const onSave = () => {
    if (editedPref !== null) {
      save.mutate(editedPref);
    }
  }

  const heading = (
      <div className={'bx--flex-line'}>
        <div className="mr-5">{t('preference-settings')}</div>
        <Loading small={true} withOverlay={false} active={save.isLoading}/>
      </div>
  );

  const [opaqueHeld, setOpaqueHeld] = useState<boolean>(false);

  const handleButtonDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === '`') {
      setOpaqueHeld(true);
    } else if (event.key === 'Enter') {
      onSave();
      event.stopPropagation();
    }
  }

  const handleButtonUp: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === '`') {
      setOpaqueHeld(false);
    }
  }

  return (

      <Modal open={props.isOpen} aria-labelledby={"Pref settings"} closeButtonLabel={t('close')} primaryButtonText={t('save')}
             onRequestClose={props.onClose} modalHeading={heading} onRequestSubmit={onSave} primaryButtonDisabled={save.isLoading}
             secondaryButtonText={t('discard')} size={'lg'} className={`pref-settings-modal ${opaqueHeld ? 'opaque' : ''}`}
             onKeyUp={handleButtonUp} onKeyPress={handleButtonDown} shouldSubmitOnEnter={false} selectorPrimaryFocus={'input#type'}>
        <Form className={'p-5'} onSubmit={(e) => {e.preventDefault()}}>
          {props.selectableData && props.insertContext && (
              <PreferenceTypeInput insertContext={props.insertContext} selectableData={props.selectableData}
                                   onPrefTypeChange={onPrefTypeChange} types={props.types}/>
          )}
          {editedPref !== null && (
              <PreferenceForm
                  editedPref={editedPref}
                  onUpdate={onEditedPrefUpdate}
                  validation={validation}
                  settingsLabels={props.settingsLabels[editedPref.associatedType.id] ?? {}}
              />
          )}
        </Form>
      </Modal>
  )
}


export default EditModal;