import {useState} from "react";

export type ValidationStructure = Record<string, string[]>;

export type InputValidationAttributes = {
  invalid: boolean;
  invalidText: string
}

export type ValidationContext = {
  clear: (key: string) => void,
  clearAll: () => void,
  getError: (key: string) => string,
  hasError: (key: string) => boolean,
  setAll: (state: ValidationStructure) => void,
  getAttributes: (key: string) => InputValidationAttributes
}

export default function useValidation() {

  const [validation, setValidation] = useState<ValidationStructure>({});

  const clear = (key: string): void => {
    setValidation((old) => {
      old[key] = [];

      return old;
    })
  }

  const clearAll = (): void => {
    setValidation({});
  }

  const getError = (key: string): string => {
    return validation.hasOwnProperty(key) ? validation[key].join(', ') : "";
  }

  const hasError = (key: string): boolean => {
    return validation.hasOwnProperty(key) && validation[key].length > 0;
  }

  const setAll = (state: ValidationStructure): void => {
    setValidation(state);
  }

  const getAttributes = (key: string): InputValidationAttributes => {
    return {
      invalid: hasError(key),
      invalidText: getError(key),
    }
  }

  return {
    clear, clearAll, getError, hasError, setAll, getAttributes
  }
}