import i18n from "i18next";
import {initReactI18next} from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    pl: {
        translation: {
            'app-name': "Preferencje",
            'prefer-title': 'Preferuję:',
            'exclude-title': 'Wykluczam:',
            'preference-settings': 'Ustawienia preferencji:',
            'pref-type': 'Typ preferencji',
            'negotiability': 'Negocjowalne?',
            'no': 'nie',
            'yes': 'tak',
            'pref-polarity': 'Grupa preferencji',
            'polarity-preference': 'Preferencja',
            'polarity-exclusion': 'Wykluczenie',
            'save': 'Zapisz',
            'close': 'Zamknij',
            'discard': 'Anuluj',
            'select': 'Wybierz',
            'exclusions': 'Wyjątki',
            'Gender': 'Płeć',
            'housing-type': 'Typ mieszkania',
            'type': 'Typ',
            'range': 'Obszar',
            'search': 'Wyszukaj',
            'set': 'Ustaw',
            'value-required': 'Wpisz wartość',
            'minimal-budget-label': 'Minimalny budżet na tydzień',
            'minimal-salary-label': 'Minimalne wynagrodzenie',
            'nightshifts-max-week': 'Max ilość nocek w tygodniu',
            'postal-code-not-selected': 'Nie wybrano żadnego kodu pocztowego',
            'selected': 'Wybrane:',
            'add': 'Dodaj',
            'night-shifts-max-week': 'Nocki w tygodniu (max)',
            'profile-to-be-migrated': 'Profil nie został jeszcze zmigrowany',
            'first-export-due': 'W trakcie pierwszego eksportu',
            'enova-sync': 'Synchronizacja z CRM',
            'in-sync': 'zsynchronizowano',
            'during-sync': 'w trakcie'
        }
    },
    en: {
        translation: {
            "app-name": "Preferences",
            'prefer-title': 'Prefers:',
            'exclude-title': 'Excludes:',
            'preference-settings': 'Additional preference settings:',
            'pref-type': 'Preference type',
            'negotiability': 'Is negotiable?',
            'no': 'no',
            'yes': 'yes',
            'pref-polarity': 'Polarity',
            'polarity-preference': 'Preference',
            'polarity-exclusion': 'Exclusion',
            'save': 'Submit',
            'close': 'Close',
            'discard': 'Discard',
            'select': 'Select',
            'exclusions': 'Exclusions',
            'housing-type': 'Housing type',
            'type': 'Type',
            'range': 'Range',
            'search': 'Search',
            'set': 'Set',
            'value-required': 'Provide value',
            'minimal-budget-label': 'Minimal budget per week',
            'minimal-salary-label': 'Minimal salary',
            'night-shifts-max-week': 'Night shifts per week (max)',
            'postal-code-not-selected': 'No postal code selected',
            'selected': 'Selected:',
            'add': 'Add',
            'profile-to-be-migrated': 'Profile not migrated yet',
            'enova-sync': 'CRM synchronization',
            'in-sync': 'done',
            'during-sync': 'in progress'
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "pl",
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

const url = new URL(window.location);
const queryParams = new URLSearchParams(url.search);
if (queryParams.get('lang') === 'en') {
    i18n.changeLanguage('en');
    document.documentElement.lang = 'en';
}

export default i18n;