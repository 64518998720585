import {PreferenceFormProps} from "../../types/PreferenceFormProps";
import {NumberInput, NumberInputOnChangeDataVariant} from "carbon-components-react";
import {Preference} from "../../types/Preference";

// todo think how to trick ts into putting a interface in here instead of <Preference>
type LocalProps = PreferenceFormProps<Preference> & {
  placeholder: string
  label: string
}

function PreferenceValueInput (props: LocalProps) {

  const changeNumberHandler: NumberInputOnChangeDataVariant = (
      ev, data
  ) => {
    props.preference.properties.value = (data.value as number | "");
    props.recordUpdated(props.preference);
  }

  return (
      <NumberInput id={`selected-value`} value={props.preference.properties.value} required={false} invalid={props.validation.hasError('settings.value')}
                   placeholder={props.placeholder} invalidText={props.validation.getError('settings.value')} label={props.label}
                   onChange={changeNumberHandler} allowEmpty={true}/>
  )
}

export default PreferenceValueInput;