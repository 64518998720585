import {useEffect, useState} from "react";

export default function useDeleteModal<T>() {

  const [open, setIsOpen] = useState<boolean>(false);
  const [subject, setSubject] = useState<T | null>(null);

  useEffect(() => {
    setIsOpen(subject !== null);
  }, [subject]);


  const set = (subject: T | null) => {
    setSubject(subject);
  }

  const reset = () => setSubject(null);

  return {open, subject, set, reset}
}