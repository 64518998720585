import {Preference, TypeBehavior} from "../Preference";
import {ImmutableMap} from "../ImmutableMap";
import {PreferenceFormProps} from "../PreferenceFormProps";
import PreferenceChecks from "../../components/PreferenceChecks/PreferenceChecks";
import {useTranslation} from "react-i18next";

type HygieneProperties = {
  checked: ImmutableMap<string, boolean>;
  genders: ImmutableMap<string, boolean>;
}

type Hygiene = Preference & {
  properties: HygieneProperties
};

export const HygieneTypeBehavior: TypeBehavior<Hygiene> = {
  formCreator: (options => <HygieneForm {...options} />),
  marshaller: ((pref, data) => {
    pref.properties.checked = ImmutableMap.selectedFromKeys(data.properties?.checked ?? []);
    pref.properties.genders = ImmutableMap.selectedFromKeys(data.properties?.genders ?? []);
  }),
  jsonReplacer: null,
  initializeProperties: pref => {
    pref.properties.checked = new ImmutableMap<string, boolean>([])
    pref.properties.genders = new ImmutableMap<string, boolean>([])
  }
}

export default Hygiene;

const HygieneForm = (props: PreferenceFormProps<Hygiene>) => {
  const {t} = useTranslation();

  return (
      <>
        <PreferenceChecks {...props} settingKey={'checked'} fieldsLabel={t('select')}
                          recordUpdated={props.recordUpdated as (pref: Preference) => any}/>

        <PreferenceChecks {...props} settingKey={'genders'} fieldsLabel={t('select')}
                          recordUpdated={props.recordUpdated as (pref: Preference) => any}/>
      </>
  )
}