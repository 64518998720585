import {Preference, TypeBehavior} from "../Preference";
import PreferenceValueInput from "../../components/MaxInput/PreferenceValueInput";
import {PreferenceFormProps} from "../PreferenceFormProps";
import {useTranslation} from "react-i18next";

export type MinimalBudgetProperties = {
  value: number | ""
}

type MinimalBudget = Preference & {
  properties: MinimalBudgetProperties;
}

export const MinimalBudgetBehavior: TypeBehavior<MinimalBudget> = {
  formCreator: options => <MinimalBudgetForm {...options} />,
  marshaller: null,
  jsonReplacer: null,
  initializeProperties: pref => {
    pref.properties.value = "";
  }
}

export default MinimalBudget;

const MinimalBudgetForm = (options: PreferenceFormProps<MinimalBudget>) => {
  const {t} = useTranslation();

  return (
      <PreferenceValueInput {...options} placeholder={t('value-required')} label={t('minimal-budget-label') + ' [EUR]'}/>
  )
}