import {ExportState} from "../../hooks/useSyncState";
import {useTranslation} from "react-i18next";

type LocalProps = {
  state: ExportState
}

export default function SystemSyncInfo (props: LocalProps) {
  const {t} = useTranslation();

  return (
      <p>
        {props.state.targetSystem}: {props.state.inSync ? t('in-sync') : t('during-sync')}
        {props.state.inSync ? (<>,&nbsp;{props.state.lastSuccess}</>) : ''}
      </p>
  )
}