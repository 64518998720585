import {getBehaviorByPref, PreferenceGroup, PreferenceType, PreferenceTypeKey} from "./PreferenceTypes";
import {ReactElement} from "react";
import {ValidationContext} from "../hooks/useValidation";
import {PreferenceSetting} from "./PreferenceSetting";

export type Preference = {
  id: number | null;
  negotiability: boolean;
  carerId: string;
  associatedType: PreferenceType
  groupKey: null | PreferenceGroup;
  textSummary: string;
  properties: Record<string, any>
  settingsSpread: PreferenceSetting[]
}

export type PreferenceCreationContext = {
  carerId: string;
  targetGroup: PreferenceGroup | null;
  targetTypeKey: PreferenceTypeKey | null;
}

export type FormCreatorOptions<T> = {
  preference: T,
  recordUpdated: (newState: T) => any,
  validation: ValidationContext,
  settingsLabels: Record<string, Record<string, string>>
};

export type formCreator<T> = (options: FormCreatorOptions<T>) => ReactElement | null;
export type JsonReplacer = (key: string, value: any) => any;

export type TypeBehavior<T> = {
  jsonReplacer: JsonReplacer | null,
  marshaller: ((pref: T, data: any) => void) | null
  formCreator: formCreator<T> | null
  initializeProperties: ((pref: T) => any) | null,
}

export const DefaultPreferenceBehavior: TypeBehavior<Preference> = {
  marshaller: null, jsonReplacer: null, formCreator: null, initializeProperties: null
}

export const PreferenceMarshaller = (data: any): Preference => {
  const pref: Preference = Object.assign({}, data);
  const prefBehavior = getBehaviorByPref(pref);
  if (prefBehavior.marshaller !== null) {
    prefBehavior.marshaller(pref, data);
  }

  return pref;
}
