import {ValidationContext} from "../../hooks/useValidation";
import * as React from "react";
import {Checkbox, FormGroup} from "carbon-components-react";
import {Preference} from "../../types/Preference";
import chunkArray from "../../functions/Array";

type LocalProps = {
  preference: Preference
  recordUpdated: (newPref: Preference) => any,
  validation: ValidationContext
  settingsLabels: Record<string, Record<string, string>>
  // key in properties to manage as checkbox group
  settingKey: string;
  // name that will display above all checks
  fieldsLabel: string;
}

function PreferenceChecks(props: LocalProps) {

  const validationKey = 'settings.' + props.settingKey;

  const newSelected = (checked: boolean, id: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const current = props.preference.properties[props.settingKey].get(id);
    props.preference.properties[props.settingKey] = props.preference.properties[props.settingKey].set(id, !current);
    props.recordUpdated(props.preference);
    props.validation.clear(validationKey);
  }

  const checks = Object.entries(props.settingsLabels[props.settingKey]).map(([key, label]) => (
      <Checkbox
          id={key}
          checked={props.preference.properties[props.settingKey].get(key) ?? false}
          labelText={label}
          key={`${props.settingKey}-${key}`}
          onChange={newSelected}
      />
  ));

  const checksChunks = chunkArray(checks, 8);

  const checkColumns = checksChunks.map((columnChecks) => (
      <div className={"bx--col-lg-4"}>
        {columnChecks}
      </div>
  ));

  return (
      <div>
        <FormGroup legendText={props.fieldsLabel} invalid={props.validation.hasError(validationKey)}>
          <div className="bx--row">
            {checkColumns}
          </div>
          <input type={'hidden'} data-invalid={props.validation.hasError(validationKey)}/>
          <div className={"bx--form-requirement"}>
            {props.validation.getError(validationKey)}
          </div>
        </FormGroup>
      </div>
  )
}

export default PreferenceChecks;