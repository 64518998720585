import {Preference, TypeBehavior} from "../Preference";
import {PreferenceFormProps} from "../PreferenceFormProps";
import {ComboBox} from "carbon-components-react";
import {useTranslation} from "react-i18next";

export type MobilityProperties = {
  selected: string | null,
}

type Mobility = Preference & {
  properties: MobilityProperties
}

export const MobilityBehavior: TypeBehavior<Mobility> = {
  formCreator: options => (<MobilityForm {...options} />),
  marshaller: null,
  jsonReplacer: null,
  initializeProperties: (pref => pref.properties.selected = null)
}

export default Mobility;

const MobilityForm = (props: PreferenceFormProps<Mobility>) => {

  const {t} = useTranslation();


  const changeType = (type: string | null | undefined) => {
    if (type) {
      props.preference.properties.selected = type;
      props.recordUpdated(props.preference);
    }
  }

  return (
      <div>
        <ComboBox id={"localization-type"} placeholder={t('type')} className={"mb-5"}
                  items={Object.keys(props.settingsLabels['selected'])}
                  itemToString={(item) => item ? props.settingsLabels["selected"][item] : ""}
                  titleText={t('type')}
                  selectedItem={props.preference.properties.selected}
                  onChange={(data) => changeType(data.selectedItem)}
                  {...props.validation.getAttributes('settings.selected')}/>
      </div>
  )
}
