import DeleteModal from "../DeleteModal/DeleteModal";
import React from "react";
import {Preference} from "../../types/Preference";
import {useMutation, useQueryClient} from "react-query";
import useApi from "../../hooks/useApi";

type LocalProps = {
  open: boolean,
  subject: Preference | null,
  onSuccess: () => any,
  onAbort: () => any,
}

const PreferenceDeleteModal = (props: LocalProps) => {
  const queryClient = useQueryClient();

  const api = useApi();

  const deletePref = useMutation((pref: Preference) => api.delete(pref), {
    onSuccess: () => {
      queryClient.invalidateQueries('prefs');
      queryClient.invalidateQueries('enovaSyncState');
      props.onSuccess();
    },
  });

  const buttonDisabled = (!deletePref.isIdle) && deletePref.isLoading;

  if (null === props.subject) {
    return null;
  }

  return (
      <DeleteModal open={props.open} onRequestSubmit={() => deletePref.mutate(props.subject as Preference)}
                   onRequestClose={props.onAbort} primaryButtonDisabled={buttonDisabled}>
        Planujesz usunąć preferencje id {props.subject.id} - {props.subject.textSummary}
      </DeleteModal>
  );
}

export default PreferenceDeleteModal;